body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
html,
body,
#root,
.App {
  height: 100%;
  width: 100%;
}

.App {
  display: flex;
  flex-direction: column;

  &-tabs {
    width: 100%;
    height: 50px;
    font-size: 16px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    &-items {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%;
      height: 100%;
      cursor: pointer;
      &:not(.selected) {
        color: #fff;
        background-color: #444;
      }
      &.selected {
        font-weight: bolder;
      }
    }
  }
}

.global {
  &__dialog .MuiDialog-paper {
    min-width: 200px;
  }
}

.non-display {
  display: none;
}

.MuiTypography-root.MuiDialogContentText-root.MuiTypography-body1 {
  word-break: break-all;
}
