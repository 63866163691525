.bd7-404 {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 0;
  flex-grow: 1;
  &__backward {
    position: absolute;
    left: 2%;
    top: 2%;
  }
}
