.demo {
  padding: 10px;
  background-color: pink;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &-button {
    margin: 10px;
    height: 100px;
    width: 250px;
    border-radius: 8px;
    border: none;
    font-size: 30px;
    background-color: white;
  }

  &-counter {
    height: 40px;
    line-height: 40px;
    text-align: center;
    background-color: yellow;
  }

  &-player {
    font-size: 18px;
  }
}
