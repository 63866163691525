.bd7-user {
  position: relative;
  width: 100%;
  height: 0;
  overflow: auto;
  flex-grow: 1;
  &__title {
    margin: 10px 0;
    text-align: center;
  }
  &__banner {
    display: flex;
    padding: 10px 10px 0 10px;
    justify-content: space-around;
    align-items: center;
    box-sizing: content-box;
    &__left {
      display: flex;
      flex-direction: column;
      align-items: center;
      &__avatar {
        height: 60px;
        width: 60px;
        min-height: 60px;
        min-width: 60px;
        padding: 2px;
        border: 2px solid #ffffff;
        background-color: #333;
        border-radius: 50%;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;
      }
      &__info {
        display: flex;
        align-items: center;
        margin-top: 10px;
        &__username {
          max-width: 80px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        &__gender {
          margin-left: 10px;
        }
      }
    }
    &__right {
      display: flex;
      align-self: stretch;
      flex-direction: column;
      justify-content: center;
      button {
        margin: 10px;
      }
    }
  }
  &__profile-wrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 10px 0;
  }
  &__follow-wrapper {
    display: flex;
  }
  &__followings {
    margin-right: 10px;
  }
  &__followings,
  &__followers {
    display: flex;
  }
  &__video-title {
    padding: 10px 20px 0 20px;
  }
  &__video-list {
    display: flex;
    flex-wrap: wrap;
    padding: 0 15px;
    margin-bottom: 20px;
    &--loading {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      min-height: 100px;
    }
  }
}

#bd7-user__banner__left__avatar-upload,
#bd7-user__video-upload,
#bd7-user__video-poster-upload {
  display: none;
}
