@import '@/static/less/reset.less';
.bd7-register {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  flex-grow: 1;
  &-backward {
    position: absolute;
    left: 0;
    top: 0;
  }
  &-title {
    text-align: center;
  }
  &-form {
    display: flex;
    margin-top: 5%;
    width: calc(100% - 60px);
    max-width: 300px;
    flex-direction: column;
    align-items: center;
  }
  &-input {
    &-password {
      margin: 20px 0 !important;
    }
    &-submit {
      display: none;
      &-button {
        margin-top: 15px;
      }
    }
  }
}
