.bd7-single-player {
  position: relative;
  width: 100%;
  height: 0;
  flex-grow: 1;
  &__hover-layer {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.5);
  }
  &__container {
    width: 100%;
    height: 100%;
  }
}
