@import '@/static/less/reset.less';
.bd7-login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  flex-grow: 1;
  &-title {
    text-align: center;
  }
  &-form {
    display: flex;
    margin-top: 5%;
    width: calc(100% - 60px);
    max-width: 300px;
    flex-direction: column;
    align-items: center;
  }
  &-input {
    &-submit {
      display: none;
      &-button {
        margin-top: 15px;
      }
    }
  }
  &-to-register {
    display: flex;
    margin-top: 20px;
    justify-content: center;
    width: calc(100% - 60px);
    position: relative;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }
}
