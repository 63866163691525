.bd7-live {
  position: relative;
  width: 100%;
  height: 0;
  overflow: auto;
  flex-grow: 1;
  &__my-live {
    min-height: 80px;
    height: 80px;
    margin-top: 10px;
    margin-bottom: 8px;
    &__status {
      display: inline-block;
      min-width: 10px;
      min-height: 10px;
      max-width: 10px;
      max-height: 10px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-left: 10px;
      &--active {
        background-color: lightgreen;
      }
      &--deactive {
        background-color: red;
      }
    }
    &__text--active {
      color: lightgreen;
      text-decoration: underline;
      cursor: pointer;
      -webkit-tap-highlight-color: transparent;
    }
    &--created {
      display: flex;
      align-items: center;
      &__left {
        display: flex;
        flex-direction: column;
        flex: 1 0 70%;
        flex-wrap: nowrap;
        height: 100%;
        padding: 0 30px;
        overflow: hidden;
      }
      &__right {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1 1 auto;
        height: 100%;
      }
    }
    &--not-created {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &--loading {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }
  }
  &__center {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 15px 40px;
    box-sizing: border-box;
    &__item {
      flex: auto;
      margin-bottom: 10px;
      &__content {
        padding: 0 0 0 30px !important;
      }
      &__actions {
        padding: 0 0 8 0px !important;
      }
    }
  }
  @media screen and (min-width: 1024px) {
    &__center {
      flex-direction: row;
      justify-content: space-around;
      &__item {
        margin: 0 10px;
        max-width: 800px;
        flex: 1 0 auto;
      }
    }
  }
}
.bd7-live-player {
  position: relative;
  width: 100%;
  height: 0;
  flex-grow: 1;
  &__hover-layer {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.5);
  }
  &__container {
    width: 100%;
    height: 100%;
  }
}
